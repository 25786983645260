import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { FiChevronDown, FiInfo } from 'react-icons/fi';
import { useCallback, useEffect, useState } from 'react';

import Link from '../../atoms/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import Slider from '../../atoms/Slider';
import debounce from 'lodash/debounce';
import getConfig from 'next/config';
import { useStyles } from './styles';
import { gql, useQuery } from '@apollo/client';

const { publicRuntimeConfig } = getConfig();

export default function PurchaseRentCalculator() {
  const classes = useStyles();
  const [hubSpotInstanceId, setHubSpotInstanceId] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState(DEFAULT_NET_RENT);
  const [data, setData] = useState({
    purchasePrice: 0,
    equity: 0,
    loanAmount: 0,
    nominalInterestRate: 0,
    effectiveAnnualInterestRate: 0,
    fixedInterestPeriodInYears: 0,
    monthlyInstallment: 0,
    totalCostOfLoan: 0,
    monthlyInstallmentCount: 0,
    financingTermYears: 0,
    financingTermMonths: 0
  });
  const { data: hubSpotInstanceData } = useQuery(FETCH_HUBSPOT_INSTANCE);

  const handleSetValue = (event, value) => {
    setLoading(true);
    setValue(value);
    fetchQuote({
      netRent: value
    });
  };
  const fetchQuote = useCallback(debounce((data) => {
    fetch(`${publicRuntimeConfig.API_ENDPOINT}/hub-spot-instances/${hubSpotInstanceId}/purchase-rent-quote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(result => result.json())
      .then(result => {
        setData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500), [hubSpotInstanceId]);

  useEffect(() => {
    if (hubSpotInstanceData?.hubSpotInstances.length > 0) {
      setHubSpotInstanceId(hubSpotInstanceData.hubSpotInstances[0].instanceId);
    }
  }, [hubSpotInstanceData?.hubSpotInstances.length]);
  useEffect(() => {
    if (hubSpotInstanceId) {
      fetchQuote({
        netRent: value,
      });
    }
  }, [fetchQuote, hubSpotInstanceId]);

  const params = new URLSearchParams({
    financingReason: 'PurchaseFinancingBuild',
    fixedInterestRate: data.fixedInterestPeriodInYears,
    purchasePrice: data.purchasePrice,
    equity: data.equity
  });
  const requestUrl = `/finanzierung-anfragen?${params.toString()}`;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mt={3}>
            <Typography variant="h6">
              Ihre monatliche Kaltmiete:
            </Typography>
          </Box>
          <Box mt={5}>
            <Slider
              value={value}
              min={0}
              max={MAX_NET_RENT}
              step={50}
              valueLabelDisplay="on"
              valueLabelFormat={(x) => x.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
              onChange={handleSetValue}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">
              Das können Sie sich leisten:
            </Typography>
            <Box mt={2} display="flex" alignItems="center">
              <Typography variant="h1">
                {isLoading ? (
                  <Skeleton style={{ width: 150 }}/>
                ) : data.purchasePrice.toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </Typography>
              <Box ml={3}>
                <Button
                  startIcon={<FiInfo/>}
                  disabled={data.purchasePrice === 0}
                  onClick={() => setModalOpen(true)}
                >
                  Annahmen
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={3}>
        <Link href={requestUrl}>
          <Button
            component="a"
            variant="contained"
            color="primary"
            size="large"
          >
            Finanzierung anfragen
          </Button>
        </Link>
      </Box>
      <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <DialogTitle>
          Annahmen
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography gutterBottom>
              Die Berechnung der maximal möglichen Darlehenssumme beruht auf der Angabe zur monatlichen Rate, einem
              gebundenen Sollzins von
              {' '}
              {data.nominalInterestRate.toLocaleString('de', { style: 'percent', minimumFractionDigits: 2 })}
              {' '}
              und
              {' '}
              {data.fixedInterestPeriodInYears} Jahre Sollzinsbindung.
            </Typography>
            <Typography gutterBottom>
              (Basierend auf einem Nettodarlehensbetrag von
              {' '}
              {data.loanAmount.toLocaleString('de', { style: 'currency', currency: 'EUR' })}
              {' '}
              und
              {' '}
              {data.equity.toLocaleString('de', { style: 'currency', currency: 'EUR' })}
              {' '}
              eingesetztem Eigenkapital für den Kauf einer selbst genutzten Immobilie, nachhaltiger Objektwert mind.
              {' '}
              {data.purchasePrice.toLocaleString('de', { style: 'currency', currency: 'EUR' })}
              {', '}
              keine Sondertilgung, erstrangige Absicherung des Darlehens über eine Grundschuld, einwandfreie Einkommens-
              und Vermögenssituation, gesichertes Angestelltenverhältnis, Auszahlung des Darlehens in einer Summe.)
            </Typography>
          </Box>
          <Box mt={3} mb={3}>
            <Accordion className={classes.accordion}>
              <AccordionSummary expandIcon={<FiChevronDown/>}>
                <Typography variant="caption">
                  Repräsentatives Beispiel
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="caption">
                  Repräsentatives Beispiel bei Vermittlung durch die BauDarlehen24 pX Vermittlungsgesellschaft mbH,
                  Berliner Allee 96, 13088 Berlin, Bonität vorausgesetzt.
                  {' '}
                  {data.nominalInterestRate.toLocaleString('de', { style: 'percent', minimumFractionDigits: 2 })}
                  {' '}
                  geb. Sollzins /
                  {' '}
                  {data.effectiveAnnualInterestRate.toLocaleString('de', {
                    style: 'percent',
                    minimumFractionDigits: 2
                  })}
                  {' '}
                  effektiver Jahreszins / {data.fixedInterestPeriodInYears} Jahre Sollzinsbindung /
                  {' '}
                  {data.monthlyInstallment.toLocaleString('de', { style: 'currency', currency: 'EUR' })}
                  {' '}
                  monatliche Rate. Zu zahlender Gesamtbetrag:
                  {' '}
                  {data.totalCostOfLoan.toLocaleString('de', { style: 'currency', currency: 'EUR' })}
                  {' '}
                  ({data.monthlyInstallmentCount} Raten,
                  Laufzeit: {data.financingTermYears} Jahre, {data.financingTermMonths} Monate). Verbraucherdarlehen für
                  Immobilien sind durch Eintragung einer Grundschuld besichert.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const MAX_NET_RENT = 5000;
const DEFAULT_NET_RENT = 1200;

const FETCH_HUBSPOT_INSTANCE = gql`
  query {
    hubSpotInstances(where: { isDefault: true }) {
      instanceId
    }
  }
`;
